// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons';
@import "~sweetalert2/src/sweetalert2";
@import "~lightgallery/css/lightgallery-bundle.min.css";
@import "~datatables.net-bs5/css/dataTables.bootstrap5.css";
@import "~@selectize/selectize/dist/css/selectize.bootstrap5.css";

body{
    color: white;
}

a{
    text-decoration: none;
    color: white;
    &:hover{
        text-decoration: underline;
        color: white;
    }
}

.userCircle{
    float: left;
    margin-left: -15px;
    &:first-child{
        margin-left: 0px;
    }
    img:hover{
        border: gray 2px solid;
    }
}

.projectCard{
    padding-left: 0;
    padding-right: 0;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

@import "army_card";


.dice-6-fill{
    background-color: green;
}

.pointer{
    &:hover{
        cursor: pointer;
    }
}

.project-nav{
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
        background-color: #000000;
    }
    .nav-link{
        color: white;
    }
}

.pos-carousel-indicators{
    .pos-slide-dot{
        border: 1px solid red;
        width: 10px;
        height: 10px;
        border-radius: 5px;
    }
    .active{
        background-color: red;
    }
}

.pos-share-icon{
    font-size: 30px;
    margin-right: 25px;
}

.news{
    img{
        max-width: 100%;
        height: auto;
    }
}

.carousel.pointer-event {
    touch-action: pan-y pinch-zoom;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.nav-link{
    color: white;
}
