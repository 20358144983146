.pos-card-list {
    display: flex;
    padding: 3rem;
    overflow-x: scroll;
}

.pos-card-list::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.pos-card-list::-webkit-scrollbar-thumb {
    background: #201c29;
    border-radius: 10px;
    box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%,.25), inset -2px -2px 2px rgba(0,0,0,.25);
}

.pos-card-list::-webkit-scrollbar-track {
    width: 10px;
    height: 10px;
}

.pos-card {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 385px;
    width: 400px;
    min-width: 250px;
    max-width: 250px;
    padding: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 16px;
    background: #17141d;
    box-shadow: -1rem 0 3rem #000;

    color: white;

    transition: .2s;
}

.pos-card:hover {
    transform: translateY(-1rem);
}

.pos-card:hover~.pos-card{
    transform:translateX(130px);
}

.pos-card:not(:first-child) {
    margin-left: -130px;
}

.pos-card-header h5:hover {
    background: linear-gradient(90deg, #ff8a00, #e52e71);
    text-shadow: none;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
}

.army-card-grid{

    .pos-card:hover {
        transform: none;
    }

    .pos-card:hover~.pos-card{
        transform: none;
    }

    .pos-card:not(:first-child) {
        margin-left: 0px;
    }

    .pos-card{
        height: auto;
        max-width: none;
        width: auto;
        border: 1px solid white;
        min-height: 410px;
    }


}
